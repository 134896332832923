import axios from 'axios';
import PropTypes from 'prop-types';

import React from 'react';
import { connect } from 'react-redux';

import CustomHead from '../../components/CustomHead.jsx';
import Pages from '../../components/Pages.jsx';
import ServerNotAvailable from '../../components/ServerNotAvailable.jsx';
import AppGalery from '../../components/app/Galery.jsx';
import StartPopup from '../../components/app/Start.jsx';
import InfoPopupWrapper from '../../components/app/infoPopup/InfoPopupWrapper.jsx';
import PopupWrapper from '../../components/app/popups/Wrapper.jsx';

import checkActualCar from '../../functions/app/checkActualCar';
import checkAuth from '../../functions/app/checkAuth';
import handlerOrderPopup from '../../functions/app/handlerOrderPopup';
import handlerPopup from '../../functions/app/handlerPopup';
import logoutActions from '../../functions/app/logoutActions';
import connectSockets from '../../functions/connectSockets';
import getHeaders from '../../functions/getHeaders';
import setServerData from '../../functions/setServerData';
import setTheme from '../../functions/setTheme';

import { dispatcher } from '../../redux/redux';
import '../../scss/app/main.scss';
import Join from '../crm/Join.jsx';
import Auth from './Auth.jsx';
import Balance from './Balance.jsx';
import Chat from './Chat.jsx';
import CorporationsPopup from './CorporationsPopup.jsx';
import Index from './Index.jsx';
import Notifications from './Notifications.jsx';
import Pays from './Pays.jsx';
import Settings from './Settings.jsx';
import Start from './Start.jsx';

// import Corporations from './Corporations.jsx';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        if (localStorage.getItem('isAppReload')) {
            this.isAppReload = true;

            localStorage.removeItem('isAppReload');

            dispatcher({ type: 'isHideStartPopup', data: true }).then(() => {
                setTheme();
            });
        }

        this.sendGps = this.sendGps.bind(this);
        this.handlerSocket = this.handlerSocket.bind(this);
        this.changeCorporation = this.changeCorporation.bind(this);
        this.visibilityDocChange = this.visibilityDocChange.bind(this);
    }

    pages = {
        start: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Start />
                    </div>
                );
            },
        },
        auth: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Auth />
                    </div>
                );
            },
        },
        index: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Index />
                    </div>
                );
            },
        },
        balance: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Balance />
                    </div>
                );
            },
        },
        settings: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Settings />
                    </div>
                );
            },
        },
        chat: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Chat />
                    </div>
                );
            },
        },
        pays: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Pays />
                    </div>
                );
            },
        },
        notifications: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Notifications />
                    </div>
                );
            },
        },
        // corporations: {
        //     render() {
        //         return (
        //             <div className="body__pageInner">
        //                 <Corporations />
        //             </div>
        //         );
        //     },
        // },
        'corporations-list': {
            render() {
                return (
                    <div className="body__pageInner">
                        <CorporationsPopup />
                    </div>
                );
            },
        },
        join: {
            render() {
                return (
                    <div className="body__pageInner">
                        <Join />
                    </div>
                );
            },
        },
    };

    intervalSendGpsId;

    checkSendGps(isStart) {
        const { user, isAcceptGetGeo } = this.props;

        if (user && isAcceptGetGeo) {
            if (isStart) {
                this.sendGps();
            }

            if (this.intervalSendGpsId) {
                clearInterval(this.intervalSendGpsId);
            }

            this.intervalSendGpsId = setInterval(() => {
                this.sendGps();
            }, 30_000);
        } else if (this.intervalSendGpsId) {
            clearInterval(this.intervalSendGpsId);
        }
    }

    getFormatedCoords(coords) {
        if (!coords || coords.length === 0) {
            return [];
        }

        return [+coords[0].toFixed(7), +coords[1].toFixed(7)];
    }

    proccessSendGps = false;

    sendGps() {
        const { user, appCoords } = this.props;

        if (user && appCoords && !this.proccessSendGps) {
            if (
                !user.coords ||
                user.coords.length === 0 ||
                this.getFormatedCoords(user.coords)[0] !== this.getFormatedCoords(appCoords)[0] ||
                this.getFormatedCoords(user.coords)[1] !== this.getFormatedCoords(appCoords)[1]
            ) {
                this.proccessSendGps = true;

                try {
                    axios
                        .patch(
                            `${process.env.REACT_APP_API}/executor`,
                            { action: 'set-gps', coords: appCoords },
                            { headers: getHeaders() },
                        )
                        .then(
                            () => {
                                checkAuth(false).then(
                                    () => {
                                        this.proccessSendGps = false;
                                    },
                                    () => {
                                        this.proccessSendGps = false;
                                    },
                                );
                            },
                            () => {
                                this.proccessSendGps = false;
                            },
                        )
                        .catch(() => {
                            this.proccessSendGps = false;
                        });
                } catch (error) {
                    this.proccessSendGps = false;
                }
            }
        }
    }

    checkDisabledGps() {
        navigator.permissions
            .query({
                name: 'geolocation',
            })
            .then(
                (permission) => {
                    if (permission.state === 'denied') {
                        dispatcher({ type: 'gpsDisabled', data: true, storage: { isSet: true } });

                        localStorage.setItem('isAcceptGetGeo', true);
                    }
                },
                () => null,
            );
    }

    setPosition(position, isForce) {
        const { latitude, longitude, accuracy } = position.coords;
        const resultCoords = [latitude, longitude];

        this.checkDisabledGps();

        if (
            (accuracy < 100 &&
                (!this.resultCoords ||
                    this.resultCoords[0] !== latitude ||
                    this.resultCoords[1] !== longitude)) ||
            !localStorage.getItem('isAcceptGetGeo') ||
            isForce
        ) {
            dispatcher({ type: 'appCoords', data: resultCoords }).then(() => {
                if (!this.isInitCheck || isForce) {
                    this.isInitCheck = true;

                    this.checkSendGps(true);
                }

                document.dispatchEvent(new CustomEvent('getGps'));

                this.resultCoords = resultCoords;
            });
        }

        localStorage.setItem('isAcceptGetGeo', true);
    }

    handlerGpsError(error) {
        if (error.code === error.PERMISSION_DENIED) {
            dispatcher({ type: 'gpsDisabled', data: true, storage: { isSet: true } });

            localStorage.setItem('isAcceptGetGeo', true);
        }
    }

    settingsGps = {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 3_000,
    };

    intervalGpsId;

    getGps() {
        const { user, isAcceptGetGeo } = this.props;

        if (user && isAcceptGetGeo) {
            this.checkDisabledGps();

            if (!this.intervalGpsId) {
                this.intervalGpsId = setInterval(() => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            this.setPosition(position);
                        },
                        this.handlerGpsError,
                        this.settingsGps,
                    );
                }, 3_000);

                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        dispatcher({ type: 'gpsDisabled', data: false, storage: { isSet: false } });

                        this.setPosition(position, true);
                    },
                    this.handlerGpsError,
                    this.settingsGps,
                );
            }
        } else if (this.intervalGpsId) {
            this.intervalGpsId = clearInterval(this.intervalGpsId);
        }
    }

    changeCorporation() {
        if (this.intervalGpsId) {
            this.intervalGpsId = clearInterval(this.intervalGpsId);
        }

        this.isInitCheck = false;

        this.getGps();

        localStorage.removeItem('appCarImagesComplete');

        checkActualCar({});
    }

    handlerOnline({ online }) {
        axios.patch(
            `${process.env.REACT_APP_API}/v2/login-app`,
            { action: 'online', online },
            { headers: getHeaders() },
        );
    }

    visibilityDocChange({ detail: { isActive } }) {
        const { user, socket } = this.props;

        if (user) {
            this.handlerOnline({ online: isActive });
        }

        if (isActive) {
            if (user && socket && !socket.connected) {
                connectSockets(true);
            }

            if (user) {
                checkAuth(false).then(
                    () => null,
                    () => null,
                );
            }

            this.intervalGpsId = clearInterval(this.intervalGpsId);

            this.getGps();
        } else if (this.intervalGpsId) {
            this.intervalGpsId = clearInterval(this.intervalGpsId);
        }
    }

    handlerSocket({ detail }) {
        const { user } = this.props;
        const { name, data } = detail;

        if (name === 'appExecutor') {
            const { fields } = data;

            dispatcher({ type: 'user', data: { ...user, ...fields } }).then(() => {
                checkActualCar({});

                document.dispatchEvent(new CustomEvent('changeUser', { detail: data }));
            });
        }

        if (name === 'system') {
            const { version } = data;

            if (version !== +process.env.REACT_APP_APP_VERSION) {
                dispatcher({ type: 'user', data: { ...user, systemVersion: version } }).then(() => {
                    handlerPopup({ name: 'appNewVersionPopup', isShow: true });
                });
            }
        }

        if (name === 'executor' && data?.type === 'logout') {
            logoutActions();
            handlerPopup({
                name: 'appLogoutPopup',
                isShow: true,
            });
        }

        if (name === 'order') {
            const { idOfOrder, deliveryType, type, fields } = data;

            if (type === 'choiceCrew') {
                document.dispatchEvent(new CustomEvent('changeOrders'));

                checkAuth(false).then(
                    () => null,
                    () => null,
                );

                handlerOrderPopup({ action: 'add', id: idOfOrder, deliveryType });
            }

            if (type === 'cancelFromCrew') {
                document.dispatchEvent(new CustomEvent('changeOrders'));

                handlerOrderPopup({ id: idOfOrder });

                checkAuth(false).then(
                    () => null,
                    () => null,
                );

                handlerPopup({
                    name: 'appCancelCrewOrderPopup',
                    isShow: true,
                    order: {
                        _id: data.idOfOrder,
                        number: fields.number,
                        dateOfOrder: fields.dateOfOrder,
                    },
                });
            }

            if (type === 'changeInfo' && fields.status === 'cancel') {
                document.dispatchEvent(new CustomEvent('changeOrders'));

                checkAuth(false).then(
                    () => null,
                    () => null,
                );

                if (fields.number && fields.dateOfOrder) {
                    handlerPopup({
                        name: 'appCancelOrderPopup',
                        isShow: true,
                        order: {
                            _id: data.idOfOrder,
                            number: fields.number,
                            dateOfOrder: fields.dateOfOrder,
                        },
                    });
                }
            }

            if (type === 'changeInfo' && fields.actionChangeRoute) {
                handlerPopup({ name: 'appOrderPointPopup', isShow: false });
                handlerPopup({
                    name: 'appChangeOrderPopup',
                    isShow: true,
                    order: {
                        _id: data.idOfOrder,
                        number: fields.number,
                        dateOfOrder: fields.dateOfOrder,
                        address: fields.addressChangeRoute,
                        action: fields.actionChangeRoute,
                        sk: fields.skChangeRoute,
                        ttk: fields.ttkChangeRoute,
                    },
                });
            }
        }

        if (name === 'joinContracts') {
            const { id, fields } = data;

            if (user.contract?._id === id) {
                const updatedContract = JSON.parse(JSON.stringify(user.contract));

                Object.keys(fields).forEach((key) => {
                    updatedContract[key] = fields[key];
                });

                dispatcher({ type: 'user', data: { ...user, contract: updatedContract } }).then(
                    () => {
                        document.dispatchEvent(new CustomEvent('changeUser', { detail: data }));
                    },
                );
            }
        }

        if (name === 'pays') {
            const { fields } = data;

            if (fields?.isUpdatePays || fields?.item?.status === 'completed') {
                checkAuth(false).then(
                    () => null,
                    () => null,
                );
            }
        }
    }

    componentDidMount() {
        const { user } = this.props;

        if (user) {
            checkAuth().then(
                () => null,
                () => null,
            );
        } else {
            localStorage.removeItem('appOrdersPopups');
        }

        this.getGps();

        document.addEventListener('getSocketData', this.handlerSocket);
        document.addEventListener('changeCorporation', this.changeCorporation);
        document.addEventListener('visibilityDocChange', this.visibilityDocChange);

        window.addEventListener('online', () => {
            handlerPopup({ name: 'appNetworkConnectPopup', isShow: false });

            document.dispatchEvent(
                new CustomEvent('networkConnect', { detail: { isActive: true } }),
            );
        });

        window.addEventListener('offline', () => {
            handlerPopup({ name: 'appNetworkConnectPopup', isShow: true });

            document.dispatchEvent(
                new CustomEvent('networkConnect', { detail: { isActive: false } }),
            );
        });

        setServerData('executorOrganizations');
    }

    componentDidUpdate() {
        this.getGps();
        this.checkSendGps();
    }

    render() {
        const { pagesStore } = this.props;
        const isOpenOrderChat = !!pagesStore['order-chat']?.state?.isShow;

        return (
            <>
                {process.env.REACT_APP_ENV !== 'local' && !this.isAppReload && <StartPopup />}
                <InfoPopupWrapper />
                <CustomHead title="Livecargo App" description="Служба доставки для бизнеса">
                    <link rel="icon" href={require('../../img/app/favicon.ico')} />
                    <link
                        rel="apple-touch-icon"
                        type="image/png"
                        href={require(
                            `../../pwa/${
                                process.env.REACT_APP_ENV === 'dev'
                                    ? 'app-dev-icon.png'
                                    : 'app-icon.png'
                            }`,
                        )}
                    />
                </CustomHead>
                <PopupWrapper name="authCodePopup" />
                <PopupWrapper name="appStartPopup" />
                <PopupWrapper name="appBalancePopup" />
                <PopupWrapper name="appLogoutPopup" />
                <PopupWrapper name="appGpsPopup" />
                <PopupWrapper name="appNetworkConnectPopup" />
                <PopupWrapper name="appInstructionApplicationPopup" />
                <PopupWrapper name="appNewVersionPopup" />
                <PopupWrapper name="appNotificationsPopup" />
                <PopupWrapper name="appSignContractPopup" />
                <PopupWrapper name="appSignPayPopup" />
                <PopupWrapper name="appWalletPopup" />
                <PopupWrapper name="appWebPushPopup" />
                <PopupWrapper name="appFnsActivateInfoPopup" />
                <PopupWrapper name="appPayInfoPopup" />
                <PopupWrapper name="appPayCancelPopup" />
                <PopupWrapper name="appSmzInstructionPopup" />
                <PopupWrapper name="appJoinCorporationPopup" />
                <PopupWrapper name="appContractPopup" />
                <PopupWrapper name="appTaxAboutPopup" />
                <PopupWrapper name="appTaxAmountPopup" />
                <PopupWrapper name="appTownsPopup" />
                <PopupWrapper name="appCountriesPopup" />

                <ServerNotAvailable />

                <PopupWrapper name="serverNotAvailablePopup" />

                <AppGalery />

                <Pages
                    classNamePage={`body__page _col _app ${
                        isOpenOrderChat ? '_openOrderChat' : ''
                    }`}
                    filter={(page) => !page.level}
                    pages={this.pages}
                    context={this}
                    fullHeight={true}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        pagesStore: state.pages,
        isAcceptGetGeo: state.isAcceptGetGeo,
        appCoords: state.appCoords,
        socket: state.socket,
        isHideStartPopup: state.isHideStartPopup,
    };
}

export default connect(mapStateToProps)(App);

App.propTypes = {
    user: PropTypes.object,
    pagesStore: PropTypes.object,
    isAcceptGetGeo: PropTypes.bool,
    appCoords: PropTypes.array,
    socket: PropTypes.object,
    isHideStartPopup: PropTypes.bool,
};
