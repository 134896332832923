import { createStore } from 'redux';

import { deleteCookie, getCookie, setCookie } from '../functions/handlerCookies';
import setTheme from '../functions/setTheme';

import WindowFix from '../classes/WindowFix';

const clearPath = (pathRes) => {
    let path = pathRes;
    path = path.length > 1 && path[path.length - 1] === '/' ? path.slice(0, -1) : path;
    const levels = path.split('/');

    return {
        path,
        levels,
    };
};

if (process.env.REACT_APP_SYSTEM === 'crm') {
    window.porterId = '648c2fb3dcb4c069f6604ba8';
}

const { path, levels } = clearPath(window.location.pathname.slice(1));
const user = (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))) || null;
const startProps = { gpsDisabled: !!localStorage.getItem('gpsDisabled') };
const popup = { state: -1 };

if (user) {
    const currentCorporation = user.corporations.find((item) => item.id === levels[0]);

    if (currentCorporation) {
        user.idOfCurrentCorporation = currentCorporation.id;
    }
}

const windowFix = new WindowFix();
let theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';

if (!['dark', 'light'].includes(theme)) {
    theme = 'light';

    localStorage.setItem('theme', theme);
}

const updateData = (settings, state) => {
    if (settings.type) {
        if (settings.cookies) {
            const { isSet, maxAge } = settings.cookies;

            if (isSet) {
                setCookie(settings.type, settings.data, { 'max-age': maxAge });
            } else {
                deleteCookie(settings.type);
            }
        }

        if (settings.storage) {
            const { isSet } = settings.storage;

            if (isSet) {
                localStorage.setItem(settings.type, settings.data);
            } else {
                localStorage.removeItem(settings.type);
            }
        }
    }

    let newData;

    if (settings.data === null || settings.data === undefined) {
        newData = null;
    } else if (
        typeof settings.data === 'number' ||
        typeof settings.data === 'string' ||
        typeof settings.data === 'boolean' ||
        settings.isClear
    ) {
        newData = settings.data;
    } else if (Array.isArray(settings.data)) {
        newData = settings.data;
    } else {
        newData = { ...(settings.type ? { ...state[settings.type] } : {}), ...settings.data };
    }

    return newData;
};

const reducer = (
    state = {
        ...startProps,
        pages: {},
        path,
        heightWindow: window.innerHeight,
        levelsPrev: null,
        language: 'en',
        stateOfNotification: !localStorage.getItem('stateOfNotification')
            ? true
            : !!localStorage.getItem('stateOfNotification'),
        serverData: {},
        stateTheme: -1,
        theme,
        device: document.documentElement.clientWidth > 1100 ? 'desktop' : 'mobile',
        actionsPopups: [],
        user,
        userWas: !!localStorage.getItem('user'),
        freshPasswordType: getCookie('freshPasswordType'),
        [process.env.REACT_APP_FORGET_LOGIN]: getCookie(process.env.REACT_APP_FORGET_LOGIN),
        [process.env.REACT_APP_FORGET_CODE]: getCookie(process.env.REACT_APP_FORGET_CODE),
        popupAuth: { ...popup },
        popupInfo: { ...popup },
        popupGalery: { ...popup },
        popupPolicy: { ...popup },
        popupMobileProfile: { ...popup },
        popupOptimizationRoute: { ...popup },
        popupContactsPoint: { ...popup },
        popupCommentsPoint: { ...popup },
        popupCargosPoint: { ...popup },
        popupCreateOrder: { ...popup },
        popupCorporation: { ...popup },
        popupExecutorInvite: { ...popup },
        exportPopup: { isShow: false },
        popupInstruction: {
            ...popup,
            state:
                user && !user.accountIsConfirm && !localStorage.getItem('hidePopupInstruction')
                    ? 1
                    : -1,
        },
        popupUserInfo: {
            ...popup,
            state:
                user &&
                user.accountIsConfirm &&
                user.confirmAfterInvite === false &&
                !localStorage.getItem('hidePopupUserInfo')
                    ? 1
                    : -1,
        },
        popupPay: { ...popup, state: -1 },
        supportPay: {
            ...popup,
            state: getCookie('hashForPay') && getCookie('idOfOrderPay') ? 1 : -1,
        },
        windowFix,
        supportCursor: {
            show: false,
            showAnimate: false,
            name: null,
        },
        hidePopupInstruction: localStorage.getItem('hidePopupInstruction'),
        windows: {},
        pagePrev: {},
        pagesHistory: [],
        isHideSideBar: false,
        isHideNotificationsBar: false,
        isCorporationPayPopupShow: false,
        isUploadOrdersPopupShow: !!getCookie('hashOrderStack'),
        authCodePopup: { isShow: false },
        appStartPopup: { isShow: false },
        appBalancePopup: { isShow: false },
        appCarImagesPopup: { isShow: false },
        appChangeOrderPopup: { isShow: false },
        appCancelOrderPopup: { isShow: false },
        appCompleteOrderPopup: { isShow: false },
        appCancelCrewOrderPopup: { isShow: false },
        appLogoutPopup: { isShow: false },
        appCoordsOrderPopup: { isShow: false },
        appGpsPopup: { isShow: false },
        appOrderPointPopup: { isShow: false },
        appNewPointPopup: { isShow: false },
        appNewPointsPopup: { isShow: false },
        appOrdersPopups: localStorage.getItem('appOrdersPopups')
            ? JSON.parse(localStorage.getItem('appOrdersPopups'))
            : [],
        isAcceptCookie: !!localStorage.getItem('isAcceptCookie'),
        isAcceptGetGeo: !!localStorage.getItem('isAcceptGetGeo'),
        appInfoPopup: { isShow: false },
        appNetworkConnectPopup: { isShow: false },
        appInstructionApplicationPopup: { isShow: false },
        appNewVersionPopup: { isShow: false },
        isBonusPopupShow: false,
        isUploadPaysPopupShow: false,
        verificationPopup: { isShow: false },
        paysPopup: { isShow: false },
        joinContractPopup: { isShow: false },
        signContractPopup: { isShow: false },
        chatTemplatesPopup: { isShow: false },
        appGalery: { isShow: false },
        payPopup: { isShow: false },
        chatExecutorDocPopup: { isShow: false },
        appSignContractPopup: { isShow: false },
        appSignPayPopup: { isShow: false },
        appWalletPopup: { isShow: false },
        appWebPushPopup: { isShow: false },
        appNotificationsPopup: { isShow: false },
        appFnsActivateInfoPopup: { isShow: false },
        appPayInfoPopup: { isShow: false },
        appPayCancelPopup: { isShow: false },
        appSmzInstructionPopup: { isShow: false },
        appJoinCorporationPopup: { isShow: false },
        appContractPopup: { isShow: false },
        isHideAudioNotification: !!localStorage.getItem('isHideAudioNotification'),
        newVersionShow: false,
        appTaxAboutPopup: { isShow: false },
        appTaxAmountPopup: { isShow: false },
        mvdPopup: { isShow: false },
        logsPopup: { isShow: false },
        serverNotAvailablePopup: { isShow: false },
        rolePopup: { isShow: false },
        userPopup: { isShow: false },
        corporationTariffPopup: { isShow: false },
        corporationLimitsPopup: { isShow: false },
        executorLimitsPopup: { isShow: false },
        listPopup: { currentName: null },
        tagsPopup: { isShow: false },
        mailingPopup: { isShow: true },
        appTownsPopup: { isShow: false },
        appCountriesPopup: { isShow: false },
        walletsPopup: { isShow: false },
        uploadJoinsPopup: { isShow: false },
    },
    settings,
) => {
    switch (settings.type) {
        case 'socket':
            return {
                ...state,
                ...{
                    socket: settings.data,
                },
            };
        case 'theme': {
            const resultTheme = state.theme === 'light' ? 'dark' : 'light';

            setTheme(resultTheme, state.pages, true);

            return {
                ...state,
                ...{
                    theme: resultTheme,
                },
            };
        }
        case 'user':
            settings.resolve(true);

            if (settings.data) {
                localStorage.setItem('user', JSON.stringify(settings.data));
            } else {
                localStorage.removeItem('user');
            }

            return {
                ...state,
                ...{
                    user: settings.data,
                },
            };
        case 'stateOfNotification':
            if (settings.data) {
                localStorage.setItem('stateOfNotification', true);
            } else {
                localStorage.removeItem('stateOfNotification');
            }

            return {
                ...state,
                ...{
                    stateOfNotification: settings.data,
                },
            };
        default: {
            if (settings.type) {
                const newData = updateData(settings, state);

                if (settings.resolve && typeof settings.resolve === 'function') {
                    settings.resolve(true);
                }

                return {
                    ...state,
                    ...{
                        [settings.type]: newData,
                    },
                };
            }

            const data = {};

            Object.keys(settings).forEach((key) => {
                if (key !== 'type') {
                    data[key] = settings[key];
                }
            });

            const newData = updateData({ data }, state);

            if (settings.resolve && typeof settings.resolve === 'function') {
                settings.resolve(true);
            }

            return {
                ...state,
                ...newData,
            };
        }
    }
};

const store = createStore(reducer);

function dispatcher(settings) {
    return new Promise((resolve) => {
        store.dispatch({ ...settings, ...{ resolve } });
    });
}

export { store, dispatcher, clearPath };
